/* eslint-disable @typescript-eslint/no-magic-numbers */
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CartDataService } from '@dd/shop-client-sdk';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, of, switchMap } from 'rxjs';
import { mapApiErrorResponse } from 'src/domain/mappers/error';
import { Store } from '@ngrx/store';
import { selectCart, selectCartId } from '@shop/modules/cart/store/cart.selectors';
import { TranslateService } from '@ngx-translate/core';
import { changeLanguage } from '@core/modules/language/store/language.actions';
import { mapCartItemHandleToName, mapCartItemToEcommerceItem } from 'src/domain/mappers/map-cart';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { Cart } from 'src/domain/cart/cart';
import { DOCUMENT } from '@angular/common';
import { BottomSheetAndDialog } from '@shared/components/bottom-sheet/bottom-sheet-and-dialog';
import { resetState } from '@shared/store/profile/profile.actions';
import { noopAction } from 'src/app/stores/noop.action';
import { LoginFormComponent } from '@core/auth/components/login-form/login-form.component';
import { LoginFormDialogData } from '@core/auth/components/login-form/models/login-form-dialog-data';
import { Router } from '@angular/router';
import { TagPusherService } from '@core/services/tag-pusher.service';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
import { CartService } from '@shop/modules/cart/cart.service';
import { DatoProductDataService } from '@core/services/dato/data-services/product-data.service';
import { DatoProductItem } from '@core/services/dato/interfaces/product.interface';
import * as cartAction from './cart.actions';

@Injectable()
export class CartEffects {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cartAction.getCart),
      concatLatestFrom(() => this.store.select(selectCartId)),
      concatMap(([_action, cartId]) => {
        return cartId === '-1'
          ? of(cartAction.createCart())
          : this.cartDataService.getCart(cartId).pipe(
              map((cart) => cartAction.getCartSuccess({ cart })),
              catchError((error: HttpErrorResponse) => {
                return of(cartAction.getCartError({ error: mapApiErrorResponse(error) }));
              })
            );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public createCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cartAction.createCart),
      switchMap(() =>
        this.cartDataService.createCart().pipe(
          map((cart) => cartAction.createCartSuccess({ cart })),
          catchError((error: HttpErrorResponse) => {
            return of(cartAction.createCartError({ error: mapApiErrorResponse(error) }));
          })
        )
      )
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public addToCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cartAction.addToCart),
      concatLatestFrom(() => this.store.select(selectCartId)),
      concatMap(([{ product }, cartId]) => {
        return this.cartDataService
          .addCartItem({
            cartId,
            productVariantId: product.variantId
          })
          .pipe(
            map((cart) => {
              this.tagPusherService.pushTag({
                event: TagEvent.AddToCart,
                ecommerce: {
                  items: [
                    {
                      item_brand: product.vendor,
                      item_category: product.productType,
                      item_id: product.productId,
                      item_name: product.sku,
                      sku: product.sku,
                      quantity: 1,
                      price: product.price,
                      currency: 'EUR'
                    }
                  ]
                }
              });
              return cartAction.addToCartSuccess({ cart });
            }),
            catchError((error: HttpErrorResponse) => {
              return of(cartAction.addToCartError({ error: mapApiErrorResponse(error) }));
            })
          );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public updateItemQuantity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cartAction.updateItemQuantity),
      concatLatestFrom(() => this.store.select(selectCart)),
      concatMap(([{ updateCartItemRequest }, oldCart]) => {
        const { id, cartItems } = oldCart;
        return this.cartDataService.updateCartItem({ cartId: id, ...updateCartItemRequest }).pipe(
          map((cart) => {
            const { cartItem, quantity } = updateCartItemRequest;
            const oldCartItem = cartItems.find((item) => item.variantId === cartItem.variantId);

            if (oldCartItem && oldCartItem.quantity > quantity) {
              const finalQuantity = oldCartItem!.quantity - quantity;
              const value = cartItem.unitPrice * finalQuantity;
              this.tagPusherService.pushTag({
                event: TagEvent.RemoveFromCart,
                ecommerce: {
                  currency: 'EUR',
                  value,
                  items: [
                    {
                      item_brand: cartItem.vendor,
                      item_category: cartItem.productType,
                      item_id: cartItem.productId,
                      item_name: cartItem.sku,
                      sku: cartItem.sku,
                      quantity: finalQuantity,
                      price: cartItem.unitPrice,
                      currency: 'EUR'
                    }
                  ]
                }
              });
            } else {
              const finalQuantity = quantity - oldCartItem!.quantity;
              const value = cartItem.unitPrice * finalQuantity;
              this.tagPusherService.pushTag({
                event: TagEvent.AddToCart,
                ecommerce: {
                  currency: 'EUR',
                  value,
                  items: [
                    {
                      item_brand: cartItem.vendor,
                      item_category: cartItem.productType,
                      item_id: cartItem.productId,
                      item_name: cartItem.sku,
                      sku: cartItem.sku,
                      quantity: finalQuantity,
                      price: cartItem.unitPrice,
                      currency: 'EUR'
                    }
                  ]
                }
              });
            }

            return cartAction.updateItemQuantitySuccess({ cart });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(cartAction.updateItemQuantityError({ error: mapApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getCheckoutUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cartAction.checkout),
      concatLatestFrom(() => [this.store.select(selectCartId), this.store.select(selectCart)]),
      concatMap(([_, cartId, cart]) => {
        this.tagPusherService.getClientAndSessionId();
        return this.cartDataService
          .getCartCheckout(cartId, undefined, {
            clientId: this.tagPusherService.clientId,
            sessionId: this.tagPusherService.sessionId
          })
          .pipe(
            map((checkoutUrl) => {
              this.tagPusherService.pushTag({
                event: TagEvent.AddPaymentInfo,
                ecommerce: {
                  currency: 'EUR',
                  shipping_tier: 'free',
                  value: cart.total,
                  items: cart.cartItems.map((cartItem) => mapCartItemToEcommerceItem(cartItem))
                }
              });
              this.document.location.href = checkoutUrl.url;
              return cartAction.checkoutSuccess();
            }),
            catchError((error: HttpErrorResponse) => {
              return of(cartAction.checkoutError({ error: mapApiErrorResponse(error) }));
            })
          );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public checkoutSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cartAction.checkoutSuccess),
      switchMap(() => of(cartAction.resetCart()))
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public checkoutUrlError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cartAction.checkoutError),
      switchMap(({ error }) => {
        if (error.status === HttpStatusCode.Unauthorized) {
          return this.dialog
            .open<LoginFormComponent, LoginFormDialogData>(LoginFormComponent, {
              backdropClass: 'dd-blur-backdrop',
              dialogModeWidth: '480px',
              data: {
                shouldNavigate: false
              }
            })
            .afterDismissed()
            .pipe(
              map((loggedIn) => {
                if (!loggedIn) {
                  this.navigateToShop();
                  return resetState();
                }
                return cartAction.getCart();
              })
            );
        }
        return of(noopAction());
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public cartOperation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cartAction.updateItemQuantitySuccess, cartAction.getCartSuccess, cartAction.addToCartSuccess, cartAction.createCartSuccess),
      mergeMap(({ cart }) => {
        return cart.totalQuantity === 0
          ? of(cart)
          : this.datoProductDataService
              .getProducts$(
                this.translateService.currentLang,
                cart.items.map((item) => item.product.handle),
                true,
                false,
                140
              )
              .pipe(
                map((products: DatoProductItem[]) => {
                  return {
                    ...cart,
                    items: mapCartItemHandleToName(cart.items, products)
                  };
                }),
                catchError((error) => of(error))
              );
      }),
      map((cart: Cart) => {
        return cartAction.cartOperationSuccess({ cart });
      }),
      catchError((error) => of(cartAction.cartOperationError({ error: mapApiErrorResponse(error) })))
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public cartOperationError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cartAction.updateItemQuantityError, cartAction.getCartError, cartAction.addToCartError, cartAction.createCartError),
      map(({ error }) => {
        // Create a new cart if the old one has been deleted already
        if (error.status === HttpStatusCode.NotFound) {
          return cartAction.createCart();
        }
        return cartAction.cartOperationError({ error });
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public languageChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changeLanguage),
      map(() => cartAction.getCart())
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly cartDataService: CartDataService,
    private readonly datoProductDataService: DatoProductDataService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly dialog: BottomSheetAndDialog,
    private readonly store: Store,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly tagPusherService: TagPusherService,
    private readonly http: HttpClient,
    private readonly cartService: CartService
  ) {}

  private navigateToShop(): void {
    this.router.navigate([RouteSegment.Root]);
  }
}
