/* eslint-disable @typescript-eslint/no-magic-numbers */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Product, ProductDataService } from '@dd/shop-client-sdk';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ProductItem } from '@shared/interfaces/product.interface';
import { catchError, concatMap, filter, forkJoin, map, mergeMap, of, switchMap } from 'rxjs';
import { mapApiErrorResponse } from 'src/domain/mappers/error';
import { mapProductsToProductItems, mapProductToProductItem } from 'src/domain/mappers/map-product';
import { ProductItemBatch } from 'src/domain/product/product-batch';
import { DatoProductDataService } from '@core/services/dato/data-services/product-data.service';
import { DatoTestProcessDataService } from '@core/services/dato/data-services/test-process-data.service';
import { DatoProductItem } from '@core/services/dato/interfaces/product.interface';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { Location } from '@angular/common';
import { DatoProductsPageDataService } from '@core/services/dato/data-services/products-page-data.service';
import { ApolloError } from '@apollo/client';
import { KiyohReviewProviderService } from '@shared/services/review-provider/review-provider.service';
import { mapDatoApiErrorResponse } from '../../../domain/mappers/dato-error';
import {
  getCrossSellingProductsError,
  getCrossSellingProductsSuccess,
  getProduct,
  getProductError,
  getProductFilters,
  getProductFiltersError,
  getProductFiltersSuccess,
  getProducts,
  getProductsError,
  getProductsPage,
  getProductsPageError,
  getProductsPageSuccess,
  getProductsSuccess,
  getProductSuccess,
  getTestProcess,
  getTestProcessError,
  getTestProcessSuccess
} from './products.actions';

const productCache: Map<string, Product> = new Map();

@Injectable()
export class ProductsEffects {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getProducts),
      switchMap(({ limit, imageWidth, filters }) =>
        this.productDataService.getProducts(limit).pipe(
          mergeMap((productBatch) => {
            const productSkus = productBatch.entries.map((product) => {
              productCache.set(product.sku, product);
              return product.handle;
            });
            return this.datoProductService
              .getProducts$(this.translateService.currentLang, productSkus, undefined, false, imageWidth, false, undefined, filters)
              .pipe(
                map((productItems): ProductItemBatch => {
                  // Map product items to DatoCMS product entries and sort them by the order of the DatoCMS products
                  const entries = mapProductsToProductItems(
                    productItems.filter((item) => item !== null),
                    productBatch.entries
                  );

                  // order entries by position key
                  entries.sort((a, b) => a.position - b.position);

                  return {
                    entries,
                    pageInfo: productBatch.pageInfo
                  };
                })
              );
          }),
          map((products) => getProductsSuccess({ products })),
          catchError((error: HttpErrorResponse) => {
            console.error(error);
            return of(getProductsError({ error: mapApiErrorResponse(error) }));
          })
        )
      )
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getTestProcess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getTestProcess),
      concatMap(() => {
        return this.datoTestProcessDataService.getTestProcessData$(this.translateService.currentLang).pipe(
          map((testProcess) => {
            return getTestProcessSuccess({ testProcess });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(getTestProcessError({ error: mapApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getProductFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getProductFilters),
      switchMap(() => {
        return this.datoProductService.getProductFilters$(this.translateService.currentLang, false).pipe(
          map((productFilters) => {
            return getProductFiltersSuccess({ productFilters });
          }),
          catchError((error: ApolloError) => {
            console.error(error);
            return of(getProductFiltersError({ error: mapDatoApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getProductsPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getProductsPage),
      concatMap(() => {
        return this.datoProductsPageService.getProductsPageData$(this.translateService.currentLang).pipe(
          map((productsPage) => {
            return getProductsPageSuccess({ productsPage });
          }),
          catchError((error: ApolloError) => {
            return of(getProductsPageError({ error: mapDatoApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getProduct),
      concatMap(({ handle }) => {
        const getProduct$ = (skuHandle?: string) => {
          return this.datoProductService
            .getProduct$(this.translateService.currentLang, skuHandle ? { sku: skuHandle } : { slug: handle }, true)
            .pipe(
              switchMap((productItem) => {
                return (
                  productCache.has(productItem.sku)
                    ? of(productCache.get(productItem.sku)!)
                    : this.productDataService.getProductByHandle(productItem.sku)
                ).pipe(
                  map((product): { mergedProduct: ProductItem; crossSellingProducts?: DatoProductItem[] } => {
                    const mergedProduct = mapProductToProductItem(productItem, product);
                    productCache.set(productItem.sku, product);
                    return { mergedProduct, crossSellingProducts: productItem.crossSellingProducts };
                  })
                );
              }),
              map(({ mergedProduct, crossSellingProducts }) => {
                const alternativeSlugs = mergedProduct.alternativeSlugs.map((slug) => slug.text);
                if (skuHandle || alternativeSlugs.includes(handle)) {
                  const newPath = location.pathname.replaceAll(new RegExp(`\/${skuHandle || handle}`, 'g'), `/${mergedProduct.slug}`);
                  const queryParams = location.search;
                  const hash = location.hash;
                  this.locationService.replaceState(`${newPath}${queryParams}${hash}`);
                }
                return getProductSuccess({ product: mergedProduct, crossSellingProducts });
              })
            );
        };

        return getProduct$().pipe(
          catchError(() => {
            return getProduct$(handle).pipe(
              catchError((error: HttpErrorResponse) => {
                this.router.navigate([RouteSegment.NotFound], { skipLocationChange: true });
                return of(getProductError({ error: mapApiErrorResponse(error) }));
              })
            );
          })
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getCrossSellingProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getProductSuccess),
      filter(({ crossSellingProducts }) => {
        return !!crossSellingProducts;
      }),
      switchMap(({ crossSellingProducts }) => {
        return forkJoin(
          crossSellingProducts!.map((crossSellingProduct) =>
            this.productDataService.getProductByHandle(crossSellingProduct.sku).pipe(
              mergeMap((apiProduct) => {
                return this.datoProductService
                  .getProduct$(this.translateService.currentLang, { sku: apiProduct.handle }, false, 450)
                  .pipe(map((productItem) => mapProductToProductItem(productItem, apiProduct)));
              })
            )
          )
        );
      }),
      map((products) => {
        return getCrossSellingProductsSuccess({ products });
      }),
      catchError((error: HttpErrorResponse) => {
        return of(getCrossSellingProductsError({ error: mapApiErrorResponse(error) }));
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly productDataService: ProductDataService,
    private readonly reviewProviderService: KiyohReviewProviderService,
    private readonly datoProductService: DatoProductDataService,
    private readonly datoProductsPageService: DatoProductsPageDataService,
    private readonly datoTestProcessDataService: DatoTestProcessDataService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly locationService: Location
  ) {}
}
