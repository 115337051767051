/* eslint-disable @typescript-eslint/no-magic-numbers */
import { HttpStatusCode } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { ControlsOf, FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { BOTTOM_SHEET_AND_DIALOG_DATA } from '@shared/components/bottom-sheet/bottom-sheet-and-dialog-config';
import { BottomSheetAndDialogRef } from '@shared/components/bottom-sheet/bottom-sheet-and-dialog-ref';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { BehaviorSubject, finalize, Observable, of, shareReplay, skip, Subject, takeWhile, timer } from 'rxjs';
import { EMAIL_REGEX_PATTERN } from '@core/validators/email-patter.validator';
import { LoginFormDialogData } from './models/login-form-dialog-data';
import { LoginFormVM } from './models/login-form.vm';

interface LoginForm {
  email: string;
  password: string;
}

interface SecondFactorForm {
  code: string;
}

@UntilDestroy()
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFormComponent implements OnInit {
  public readonly auth$: Observable<LoginFormVM> = this.authService.auth$;
  public readonly secondFactorAuth$ = this.authService.secondFactorAuth$;
  public readonly profile$ = this.authService.profile$;
  public readonly secondFactorCodeRequest$ = this.authService.secondFactorCodeRequest$;
  public readonly isSecondFactorAuth$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly codeResent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly errorNotificationSubject$: Subject<string> = new Subject<string>();
  public readonly errorNotification$ = this.errorNotificationSubject$.asObservable().pipe(shareReplay({ refCount: true, bufferSize: 1 }));
  public readonly form: FormGroup<ControlsOf<LoginForm>> = this.fb.group(
    {
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX_PATTERN)]],
      password: ['', [Validators.required]]
    },
    { updateOn: 'submit' }
  );
  public readonly secondFactorForm: FormGroup<ControlsOf<SecondFactorForm>> = this.fb.group(
    {
      code: ['', [Validators.required, Validators.minLength(6)]]
    },
    { updateOn: 'submit' }
  );
  public readonly RouteSegment = RouteSegment;
  public codeValidityTime = 10;
  private readonly DEFAULT_DURATION = 5000;
  private readonly SECOND_FACTOR_PASSED_DURATION = 3000;

  constructor(
    @Optional() @Inject(BOTTOM_SHEET_AND_DIALOG_DATA) public readonly loginFormDialogData: LoginFormDialogData,
    private readonly fb: FormBuilder,
    private readonly translateService: TranslateService,
    private readonly authService: AuthService,
    private readonly dialogRef: BottomSheetAndDialogRef,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    this.authService.resetState();

    if (this.loginFormDialogData?.is2FA) {
      this.setupTwoFactorAuthentication();
      this.subscribeToSecondFactorAuthResult();
    } else {
      this.subscribeToAuthResults();
    }
  }

  public subscribeToAuthResults(): void {
    const authSubscription = this.auth$
      .pipe(
        skip(1),
        takeWhile((data) => data.isAuthenticated !== true, true),
        untilDestroyed(this),
        finalize(() => this.resetLoginForm())
      )
      .subscribe((data) => {
        if (!!data.error) {
          this.form.controls.email.setErrors({ error: true });
          this.form.controls.password.setErrors({ error: true });
        }

        if (data.isAuthenticated) {
          this.resetErrorNotification();
          if (this.loginFormDialogData?.requires2FA && !this.isSecondFactorAuth$.getValue()) {
            this.subscribeToSecondFactorAuthResult();
            this.setupTwoFactorAuthentication();
            authSubscription.unsubscribe();
          } else {
            this.closeDialog(data.isAuthenticated);
          }
        }
      });
  }

  public subscribeToSecondFactorAuthResult(): void {
    this.secondFactorCodeRequest$.pipe(skip(1), untilDestroyed(this)).subscribe((data) => {
      if (data.error?.status === HttpStatusCode.Unauthorized) {
        this.isSecondFactorAuth$.next(false);
        this.subscribeToAuthResults();
      } else if (!!data.error) {
        this.codeResent$.next(true);
      }

      if (data.isCodeRequested?.isResent) {
        this.codeResent$.next(true);
      }
    });

    const secondFactorSubscription = this.secondFactorAuth$
      .pipe(
        skip(1),
        takeWhile((data) => data.isAuthenticated !== true, true),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.resetErrorNotification();

        if (data.error?.status === HttpStatusCode.Unauthorized) {
          this.isSecondFactorAuth$.next(false);
          this.authService.resetState();
          this.subscribeToAuthResults();
          this.resetSecondFactorForm();
          secondFactorSubscription.unsubscribe();
        }

        if (!!data.error) {
          this.setOtpInputError(data.error.status);
          this.setSecondFactorError(data.error.status);
        }

        if (data.isAuthenticated) {
          this.secondFactorAuthenticationPassed();
        }
      });
  }

  public resentOtpCodeMessage$(errorCode?: HttpStatusCode): Observable<string | undefined> {
    switch (errorCode) {
      case HttpStatusCode.Conflict: {
        return this.translateService.stream('auth.incorrect-code');
      }
      case HttpStatusCode.Forbidden: {
        return this.translateService.stream('auth.too-many-resent-codes');
      }
      default: {
        return this.translateService.stream('auth.code-resent');
      }
    }
  }

  public errorMessage$(control: FormControl<unknown>): Observable<string> {
    if (control.hasError('required')) {
      return this.translateService.stream('common.fill-out-this-field');
    }
    if (control.hasError('email')) {
      return this.translateService.stream('common.invalid-email');
    }
    if (control.hasError('error')) {
      // We just want to show error state, without any message.
      return of('');
    }
    return of();
  }

  public setupTwoFactorAuthentication(): void {
    this.authService.requestSecondFactorCode$.next(false);
    this.isSecondFactorAuth$.next(true);
  }

  public onSubmitLoginForm(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const { email, password } = this.form.value;
      this.authService.login(email, password, this.loginFormDialogData?.shouldNavigate);
    }
  }

  public onContactSupport(event: Event): void {
    event.preventDefault();
    this.dialogRef.dismiss();
    this.dialogRef.afterDismissed().subscribe(() => this.authService.logout());
    this.router.navigate([RouteSegment.ContactUs]);
  }

  public onSubmitSecondFactorForm(): void {
    this.authService.resetState();
    this.secondFactorForm.markAllAsTouched();
    if (this.secondFactorForm.valid) {
      const { code } = this.secondFactorForm.value;
      this.authService.secondFactorAuthenticate(code);
    }
  }

  public onResendClick(event: Event): void {
    event.preventDefault();
    this.authService.resetState();
    this.authService.requestSecondFactorCode$.next(true);
    timer(this.DEFAULT_DURATION)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.codeResent$.next(false));
  }

  public closeDialog(isAuthenticated?: boolean): void {
    this.dialogRef.dismiss(isAuthenticated);
  }

  private secondFactorAuthenticationPassed(): void {
    timer(this.SECOND_FACTOR_PASSED_DURATION)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.closeDialog());
  }

  private setOtpInputError(errorCode: HttpStatusCode): void {
    switch (errorCode) {
      case HttpStatusCode.Conflict: {
        this.secondFactorForm.controls.code.setErrors({ codeIncorrect: true });
        break;
      }
      case HttpStatusCode.Forbidden: {
        this.secondFactorForm.controls.code.setErrors({ tooManyAttempts: true });
        break;
      }
    }
  }

  private setSecondFactorError(errorCode?: HttpStatusCode): void {
    switch (errorCode) {
      case HttpStatusCode.Conflict: {
        return this.errorNotificationSubject$.next('auth.incorrect-code');
      }
      case HttpStatusCode.Forbidden: {
        return this.errorNotificationSubject$.next('auth.too-many-attempts');
      }
      case HttpStatusCode.Unauthorized: {
        return this.errorNotificationSubject$.next('auth.2fa-timeout');
      }
      default: {
        return this.errorNotificationSubject$.next('');
      }
    }
  }

  private resetSecondFactorForm(): void {
    this.secondFactorForm.reset({
      code: ''
    });
  }

  private resetLoginForm(): void {
    this.form.reset({
      email: '',
      password: ''
    });
  }

  private resetErrorNotification(): void {
    this.errorNotificationSubject$.next('');
  }
}
